@font-face {
  font-family: 'almoni-neue-regular';
  src: url("./assets/fonts/almoni-regular-aaa.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'almoni-neue-bold';
  src: url("./assets/fonts/almoni-bold-aaa.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

:root {
  --main-color: #b60072;
  --main-color-disabled: #ce78ae;
  --main-color-mid: #f180a4;
  --text-color-regular: #787878;
  --text-disabled: #bababa;
  --title-text-color: #7e7e7e;
  --app-background-color: #efefef;
  --app-disabled-btn-bg: #d0d0d0;
  --app-background-light-color: #fff;
  --app-background-light-bg: #f9f9f9;
  --app-background-disabled: #e1e1e1;
  --header-footer-background: #fff;
  --button-disabled: #ababab;

  --xs: 0;
  --sm: 576px;
  --med: 768px;
  --lg: 992px;
  --xl: 1200px;
  --xxl: 1400px;

  --font-s: 15px;
  --font-m: 16px;
  --font-l: 17px;
  --font-xl: 18px;
  --font-xxl: 22px;
  --font-xxxl: 30px;
  --font-f-title: 20px;

  --f-reg: 'almoni-neue-regular';
  --f-bold: 'almoni-neue-bold';
}

.disabled_btn{
  background-color: var(--button-disabled) !important;
}

a, a:hover{
  color: var(--main-color);
}

h1, .font-xxl{
  font-size: var(--font-xxl) !important;
}

.font-s{
  font-size: var(--font-s) !important;
}

.font-m{
  font-size: var(--font-m) !important;
}

.font-l{
  font-size: var(--font-l) !important;
}

.font-xl{
  font-size: var(--font-xl) !important;
}

.font-xxxl{
  font-size: var(--font-xxxl) !important;
}

.font-f-title{
  font-size: var(--font-f-title);
}

.f-reg{
  font-family: var(--f-reg);
}

.f-bold{
  font-family: var(--f-bold);
}

.text-color-regular{
  color: var(--text-color-regular) !important;
}

.main-color-bg{
  background-color: var(--main-color);
}

.disabled-btn-bg{
  background-color: var(--app-disabled-btn-bg);
}

.text-disabled{
  color: var(--text-disabled);
}

body{
  background-color: var(--app-background-color);
  color: var(--text-color-regular);
}

.App{
  min-height: 100%;
}

.btn-primary, .btn-primary:hover {
  background-color: var(--main-color);
}

.btn{
  border-radius: 0;
  border: none;
  outline: none;
}

.btn span{
  font-size: 13px;
}

.pointer{
  cursor: pointer;
}

.text-color-selected{
  color: var(--title-text-color);
}

.primary_color{
  color: var(--main-color) !important;
}

.btn:focus,
.form-control:focus{
  box-shadow: none;
}

.btn.btn-primary:focus{
  background-color: var(--main-color);
}

.MuiDataGrid-cellContent,
.MuiDataGrid-columnHeaderTitle{
  font-family: var(--f-reg);
}

.MuiDataGrid-cell:not(:last-of-type),
.closed .MuiDataGrid-cell{
  display: flex;
  align-items: flex-start !important;
} 

.MuiDataGrid-row .MuiDataGrid-cell:first-child{
  align-items: center !important;
}

.MuiDataGrid-cell:focus{
  outline: none !important;
}

.modal-content{
  border-radius: 0;
}

select:focus{
  box-shadow: none !important;
}

