.MainTable .odd{
    background-color: var(--app-background-light-bg);
}

.MainTable .MuiDataGrid-row{
    padding: 8px 0
}

.MainTable .css-11m4tn9-MuiDataGrid-root .MuiDataGrid-withBorderColor{
    border: none !important;
}

.MainTable .MuiDataGrid-columnHeaders{
    background-color: #e5e4e4;
    color: var(--text-color-regular)
}

.MainTable .MuiDataGrid-columnHeaderTitle{
    font-weight: 600;
}

.MainTable .MuiDataGrid-cellContent{
    color: var(--text-color-regular)
}

.MainTable .MuiDataGrid-footerContainer{
    display: none !important;
}

.MainTable .MuiDataGrid-columnHeaders{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.MainTable .MuiDataGrid-root{
    border-radius: 0;
}

.MainTable .MuiDataGrid-cell{
    align-items: flex-start;
}

.relative{
    position: relative;
}