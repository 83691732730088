.header_comp{
  margin-bottom: 55px;
  background-color: var(--header-footer-background);
}

.header_comp .header_logo{
  position: absolute;
  top: 0;
  right: 23px;
}

/* .header_comp .header_logo img{
  width: 80px;
  height: 80px;
} */

.header_comp #main_navbar{
  margin-right: 160px;
}

.header_comp #main_navbar .active,
.header_comp .navbar-collapse a:hover{
  color: var(--main-color) !important
}

.header_comp .navbar-collapse a:hover svg{
  fill: var(--main-color) !important
}

.header_comp .contact_popup{
  width: 400px;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgb(186 186 186);
  position: absolute;
  top: 60px;
  right: -245px;
  z-index: 99;
}

.header_comp .contact_popup p,
.header_comp .contact_popup input,
.header_comp .contact_popup button{
  font-size: 13px;
}

.header_comp .contact_popup input{
  background-color: #ececec;
  color: #a9a7a7;
}

nav.navbar {
  min-height: 56px;
}

.disconnect_popup{
  width: 127px;
  height: 47px;
  top: 40px;
}

.disconnect_shadow{
  box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.08);
}

.disconnect_reg{
  margin-left: 25px !important;
  width: 100px;
}