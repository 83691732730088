.MuiStep-root .MuiStepConnector-root{
    left: calc(50% + 20px);
    right: calc(-50% + 20px);
}

.MuiStepLabel-iconContainer.Mui-disabled{
    border: 1px solid var(--main-color);
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
}

.MuiStepLabel-iconContainer.Mui-disabled svg{
    color: transparent !important;
    transition: all 0.2s ease-in-out;
}

.MuiStepLabel-iconContainer.Mui-disabled .MuiStepIcon-text{
    fill: var(--main-color);
    transition: all 0.2s ease-in-out;
}

.MuiStepLabel-iconContainer .Mui-active,
.MuiStepLabel-iconContainer .Mui-completed{
    color: var(--main-color) !important;
    transition: all 0.2s ease-in-out;
}

.MuiStepConnector-root span{
    border-color: var(--main-color);
    transition: all 0.2s ease-in-out;
}

.ActionForm .btn-primary{
    width: 124px;
}

.ActionForm .btn-primary:disabled{
    background-color: var(--main-color-disabled);
}

.MuiStepLabel-label{
    color: var(--main-color);
    font-family: 'almoni-neue-regular' !important;
}

.MuiStepLabel-label.Mui-active{
    color: var(--main-color) !important;
    font-weight: bold !important;
    font-family: 'almoni-neue-bold';
} 

.ActionForm .stepper_con{
    width: 585px;
}

.ContactSection_form,
.ProjectSection_form{
    max-width: 425px;
}

.PartnerSection_form{
    width: 900px;
}

.ActionForm{
    min-height: 73vh;
}

.MuiSwitch-root.MuiSwitch-sizeMedium{
    margin-left: -7px;
}

.input-group input,
.input-group textarea,
.input-group .form-select,
.input-group select{
    border: 0;
    resize: none;
    color: var(--title-text-color);
    border-radius: 0 !important;
}

.input-group input:disabled
.input-group textarea:disabled{
    background-color: #e1e1e1;
}

.partnerBlock input:disabled,
.budgetBlock input:disabled,
.partnerBlock textarea:disabled,
.partnerBlock select:disabled{
    background-color: #f0f0f0;
}



.was-validated textarea.form-control:valid, 
textarea.form-control.is-valid,
.was-validated .form-select:invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple]):not([size]) {
    padding-right: 10px;
}

.disabled_back{
    background-color: var(--app-background-disabled);
}

.close_circle_icon.gray_block{
    top: 5px;
    left: 9px;
}

.close_circle_icon.gray_block svg{
    width: 15px;
}

.close_circle_icon.gray_block:hover svg path{
    fill: var(--main-color);
}

.disabled_back .close_circle_icon.gray_block:hover svg path{
    fill: #D8D8D8;
}

.is-invalid.form-control:valid{
    background-image: none;
}

.MuiLinearProgress-bar{
    background-color: var(--main-color) !important;
}

.alert-primary{
    background-color: #b6007230;
}

.ProjectSection_form input:disabled{
    background-color: var(--app-background-disabled);
}