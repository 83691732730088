.MobileAlert{
    height: 100vh;
    background-image: url('../../assets/images/pink_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px 10%;
    font-size: 20px;
}

.MobileAlert p{
    line-height: 30px;
}