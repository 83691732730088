.Projects .main_table_buttons .main_page_btn{
    width: 150px;
    height: 36px;
}

.Projects .title{
    color: var(--title-text-color);
}

.Projects{
    min-height: 73vh;
}

.pop_title{
    padding: 16px 24px 0;
}

.btn_icon{
    margin-top: -1px;
}

.search_input span{
    background-color: #fff;
    border: 0
}

.search_input input{
    border-left: none;
    border: 0;
}

.databox{
    background-color: #fff;
    width: 173px;
    height: 117px;
}

.login_popup{
    height: 350px;
}

.login_popup p.text-light{
    max-width: 195px;
}

.login_popup .btn-primary:disabled{
    background-color: var(--app-disabled-btn-bg);
}

.w-43{
    width: 43%;
}

.h-26{
    height: 26px;
}

.center-box{
    left: calc(50% - 14px)
}
.close_circle_icon{
    top: 19px;
    left: 19px;
}

.close_circle_icon.filt{
    left: 16px;
    top: 16px;
}

.filters_con{
    width: 334px;
    margin-top: -50px;
    right: -10px;
}

.filters_con select{ /*.filters_con input*/
    border: 1px solid #dad8d8 !important;
}

.filters_con .search_input{
    background-color: #f2f2f2;
    border-radius: 0;
    height: 40px;
}

.MuiAutocomplete-inputRoot{
    border-radius: 0px !important;
}

.filter_switch .MuiSwitch-root{
    direction: ltr;
}